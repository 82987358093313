<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      color="success"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-file-document-box-multiple-outline</v-icon> Управление новостями сайта
        </div>
      </template>
      <template #after-heading>
        <v-btn
          color="success"
          @click="addNew"
        >
          Добавить
        </v-btn>
      </template>
      <v-row
        class="mt-3"
        style="min-height: 400px"
      >
        <v-data-table
          :headers="headersContent"
          :items="articlesByType"
          sort-by="calories"
          :loading="getArtlist"
          style="width: 100%"
          @dblclick:row="editArticle"
        >
          <template #item.actions="{ item }">
            <v-icon
              class="mr-2"
              @click="editArticle(null, { item: item })"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-row>
    </base-material-card>
    <v-dialog
      ref="dialog"
      v-model="popup"
      persistent
      scrollable
      width="890px"
    >
      <v-form ref="contentform">
        <v-card>
          <v-card-title>
            <v-spacer />
            <v-icon
              aria-label="Close"
              @click="popup = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text class="text-body-1">
            <div class="pa-3">
              <v-select
                v-model="article.siteid"
                :items="sites"
                item-value="id"
                item-text="title"
                label="Cайт"
                outlined
                hide-details
                class="mt-3"
                required
              />
              <v-text-field
                v-model="article.art_title"
                :rules="nameRules"
                label="Заголовок"
                required
              />
              <tiptap-vuetify
                v-model="article.content"
                :extensions="extensions"
              />
              <v-textarea
                v-model="article.shortcontent"
                outlined
                rows="3"
                counter="256"
                :rules="[v => !!v && v.length < 256 || 'Текст должен быть меньше 256 символов!']"
                label="Короткое описание"
                class="mt-5"
              >
                <template #prepend>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        depressed
                        class="ma-0 pt-0"
                        v-on="on"
                        @click.stop="copyText(article.content)"
                      >
                        <v-icon>mdi-book-arrow-right-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Копировать с основного текста</span>
                  </v-tooltip>
                </template>
              </v-textarea>
              <v-row class="mt-2">
                <v-col
                  class="col-12 col-md-4"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="article.dateofpublish"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="article.dateofpublish"
                        label="Дата публикации"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="article.dateofpublish"
                      :first-day-of-week="1"
                      no-title
                      scrollable
                      locale="ru-ru"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(article.dateofpublish)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  class="col-12 col-md-4"
                >
                  <v-checkbox
                    v-model="allowedCont"
                    label="Доступен"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(file, index) in article.filelist"
                  :key="file"
                  cols="12"
                  md="3"
                >
                  <v-card>
                    <v-img
                      :src="`${apiurl}/WSD/image/${article.siteid}/${article.art_id}/${file}`"
                    />
                    <v-card-actions class="pa-1">
                      <v-spacer />
                      <v-btn
                        :color="article.mainimg == file?'red': ''"
                        icon
                        @click="article.mainimg = file"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        :loading="imgDeleting"
                        @click="delImg([file, index])"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-file-input
                v-model="filesNew"
                accept=""
                color="deep-purple accent-4"
                counter
                label="Добавить изображение"
                placeholder="Нажмите, или перетащите файл в это окно"
                prepend-icon="mdi-paperclip"
                :disabled="fileUploading"
                :display-size="1000"
                @change="addImg"
              />
              <v-text-field
                v-model="article.route"
                label="Текстовый айди для ссылки"
              />
              <v-textarea
                v-model="article.metatitle"
                outlined
                rows="3"
                counter="128"
                class="mt-3"
                label="Мета описание"
              >
                <template #prepend>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        depressed
                        class="ma-0 pt-0"
                        v-on="on"
                        @click.stop="copyMeta(article.shortcontent)"
                      >
                        <v-icon>mdi-book-arrow-right-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Копировать с короткого описания без 'Читать подробнее...'</span>
                  </v-tooltip>
                </template>
              </v-textarea>
              <v-textarea
                v-model="article.metatags"
                outlined
                rows="3"
                counter="256"
                label="Мета теги"
                class="mt-5"
              />
              <v-textarea
                v-model="article.searchfilter"
                outlined
                rows="3"
                counter="128"
                label="Параметры поиска связаных вакансий"
                class="mt-5"
              />
            </div>
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer />
            <v-btn
              color="error"
              depressed
              default
              text
              @click="popup = false"
            >
              отменить
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              depressed
              default
              text
              :loading="contentCreated"
              @click="updateContent(article)"
            >
              сохранить
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Image } from 'tiptap-vuetify'
  // import FileSelector from '~/Components/FileSelector'

  export default {
    // specify TiptapVuetify component in "components"
    components: { TiptapVuetify },
    data: () => ({
      articleSnap: null,
      imgDeleting: false,
      getArtlist: false,
      fileUploading: false,
      contentCreated: false,
      filesNew: null,
      menu: false,
      addNewPopup: false,
      popup: false,
      articles: [],
      article: {
        arttype: 1,
      },
      sites: [],
      nameRules: [
        v => !!v || 'Необходимо заполнить заголовок',
      ],
      articleTypes: [
        {
          id: 0,
          name: 'Новость',
        },
        {
          id: 1,
          name: 'Интересное',
        },
        {
          id: 2,
          name: 'Акция',
        },
        {
          id: 3,
          name: 'Отзывы',
        },
        {
          id: 4,
          name: 'Направления',
        },
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        Image,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        Link,
        Code,
        HorizontalRule,
        HardBreak,
      ],
    }),
    computed: {
      articlesByType () {
        return this.articles.filter(art => art.arttype === this.artType)
      },
      artType () {
        const type = ['/siteadmin/news', '/siteadmin/interesting', '/siteadmin/promotion', '/siteadmin/comment', '/siteadmin/directions']
        const typeUse = type.indexOf(this.$route.path)
        return typeUse
      },
      apiurl () {
        return this.$store.state.url
      },
      allowedCont: {
        get () {
          return !!this.article.allowed
        },
        set (val) {
          this.article.allowed = val ? 1 : 0
        },
      },
      isNews: {
        get () {
          return !!this.article.news
        },
        set (val) {
          this.article.news = val ? 1 : 0
        },
      },
      headersContent () {
        return [
          {
            text: 'id',
            align: 'left',
            width: '2%',
            value: 'art_id',
            sortable: true,
          },
          {
            text: 'Дата публикации',
            align: 'left',
            width: '10%',
            value: 'dateofpublish',
            sortable: true,
          },
          {
            text: ' Сайт',
            align: 'center',
            width: '15%',
            value: 'site_url',
            sortable: false,
          },
          {
            text: 'Заголовок',
            align: 'left',
            width: '15%',
            value: 'art_title',
            sortable: false,
          },
          {
            text: ' Короткое содержание',
            align: 'left',
            width: '50%',
            value: 'shortcontent',
            sortable: false,
          },
          { text: '', value: 'actions', width: '10%', sortable: false },
        ]
      },
    },
    mounted () {
      this.getArtlist = true
      this.$store.dispatch('getWSsites').then((sites) => {
        this.sites = sites
        this.sites.forEach(site => {
          console.log('aa', site)
          this.$store.dispatch('getWSarticles', { siteid: site.id, arttype: this.artType }).then((articles) => {
            this.articles = this.articles.concat(articles)
            this.getArtlist = false
          })
        })
      })
    },
    methods: {
      delImg ([imgName, index]) {
        this.imgDeleting = true
        const delFile = { siteid: this.article.siteid, artid: this.article.art_id, fileid: imgName.split('.')[0] }
        this.$store.dispatch('deleteImage', delFile).then(() => {
          this.article.filelist.splice(index, 1)
          this.imgDeleting = false
        })
      },
      async addImg () {
        if (this.article.art_id) {
          this.fileUploading = true
          const uploadFile = { siteid: this.article.siteid, artid: this.article.art_id, filebody: this.filesNew, flgmainimg: '0' }
          const fileid = await this.$store.dispatch('uploadArticleImg', uploadFile)
          // console.log(this.filesNew)
          this.article.filelist.push(fileid.result + '.' + this.filesNew.name.split('.').slice(-1)[0])
          this.filesNew = null
          this.fileUploading = false
        }
      },
      copyText (text) {
        if (!text) return
        this.article.shortcontent = text.replace(/<[^>]*>?/gm, '').trim()
        if (this.article.shortcontent.length > 250) {
          this.article.shortcontent = this.article.shortcontent.slice(0, 250) + '...'
        }
      },
      copyMeta (text) {
        if (!text) return
        this.article.metatitle = text.replace(/<[^>]*>?/gm, '').replace('Читать подробнее...', '').trim()
      },
      addNew () {
        this.article = {
          art_id: '',
          art_title: '',
          content: '',
          shortcontent: '',
          dateofpublish: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          mainimg: '',
          metatags: '',
          metatitle: '',
          ogtitle: '',
          ogdesc: '',
          ogimg: '',
          siteid: '',
          filelist: null,
          allowed: 1,
          news: this.artType,
        }
        this.contentCreated = false
        this.articleSnap = JSON.stringify(this.article)
        this.popup = true
      },
      async updateContent () {
        this.contentCreated = true
        if (this.$refs.contentform.validate() && JSON.stringify(this.article) !== this.articleSnap) {
          try {
            const createdArticle = await this.$store.dispatch('setContent', this.article)
            if (this.filesNew) {
              const uploadFile = { siteid: this.article.siteid, artid: createdArticle.result, filebody: this.filesNew, flgmainimg: '1' }
              await this.$store.dispatch('uploadArticleImg', uploadFile)
            }
            this.$store.dispatch('setSnackbar', { state: true, text: 'Контент добавлен', color: 'success', direction: 'top center' })
            this.article = {
              art_id: '',
              art_title: '',
              content: '',
              shortcontent: '',
              dateofpublish: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              mainimg: '',
              metatags: '',
              metatitle: '',
              ogtitle: '',
              ogdesc: '',
              ogimg: '',
              siteid: '',
              route: '',
              searchfilter: '',
              filelist: null,
              allowed: 1,
              news: this.artType,
            }
            this.filesNew = null
            this.articles = []
            this.getArtlist = true
            this.$store.dispatch('getWSsites').then((sites) => {
              this.sites = sites
              this.sites.forEach(site => {
                this.$store.dispatch('getWSarticles', { siteid: site.id, arttype: this.artType }).then((articles) => {
                  this.articles = this.articles.concat(articles)
                })
              })
              this.getArtlist = false
            })
            this.popup = false
          } catch (e) {
            console.log(e)
            this.$store.dispatch('setSnackbar', { state: true, text: e, color: 'warning', direction: 'top center' })
            this.contentCreated = false
          }
        } else {
          this.contentCreated = false
        }
      },
      editArticle (event, itemObj) {
        const item = itemObj.item
        this.contentCreated = true
        this.$store.dispatch('getWSarticle', { siteid: item.art_siteid, artid: item.art_id }).then((fullArt) => {
          console.log(fullArt)
          this.article = {
            art_id: fullArt.art_id,
            art_title: fullArt.art_title,
            content: fullArt.content,
            shortcontent: fullArt.shortcontent,
            dateofpublish: fullArt.dateofpublish,
            mainimg: fullArt.mainimg,
            metatags: fullArt.metatags,
            metatitle: fullArt.metatitle,
            ogtitle: '',
            ogdesc: '',
            ogimg: '',
            siteid: fullArt.art_siteid,
            filelist: fullArt.filelist ? fullArt.filelist.split(';') : [],
            allowed: fullArt.flgactive,
            news: fullArt.arttype,
            route: fullArt.route,
            searchfilter: fullArt.searchfilter,
          }
          this.article.dateofpublish = (new Date(new Date(this.article.dateofpublish) - (new Date(this.article.dateofpublish)).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.articleSnap = JSON.stringify(this.article)
          this.popup = true
          this.contentCreated = false
        }).catch((e) => {
          this.contentCreated = false
        })
      },
      getArticle ([siteid, artid]) {
        const value = { siteid: siteid, artid: artid }
        this.$store.dispatch('getWSarticle', value)
      },
    },
  }
</script>
<style>
  .tiptap-vuetify-editor__content{
    min-height: 200px;
    padding: 10px;
  }
</style>
